@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Header_header__1CGKd { 
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 4rem;
  display: flex;
  background-image: linear-gradient(to top right, #E6DCD0, #fff);
  z-index: 10;
}

.Header_header__1CGKd.Header_active__2MpfG {
  background: #fff;
}

.Header_name__1EWSV {
	font-size: 2rem;
	font-weight: 700;
  font-family: 'Roboto';
  padding-left: 15px;
  margin: auto auto auto 0;
	color: #786A5B;
	opacity: .7;
  text-decoration: none;
}

.Header_name__1EWSV:hover,
.Header_name__1EWSV:focus {
  text-decoration: none;
  color: #786A5B;
}

@media(min-width: 576px) and (max-width: 767px) {
  .Header_name__1EWSV {
    font-size: 1.8rem;
  }
}

@media(max-width: 575px) {
  .Header_name__1EWSV {
    font-size: 1.2rem;
  }
}
.LinkButton_link-button__29vMb {
  background-color: transparent;
  font-size: 1.2rem;
  font-family: 'Roboto';
  font-weight: 400;
	position: relative;
	padding: 1rem;
  border-bottom: .5rem;
  color: #786A5B;
  opacity: .7;
  text-decoration: none;
  border: 0px;
}

.LinkButton_link-button__29vMb:focus,
.LinkButton_link-button__29vMb:hover {
  text-decoration: none;
  font-weight: 400;
  color: #6d5e4f;
  opacity: 1;
}

@media(min-width: 576px) and (max-width: 767px) {
  .LinkButton_link-button__29vMb {
    font-size: 1rem;
    padding: .8rem;
  }
}

@media(max-width: 575px) {
  .LinkButton_link-button__29vMb {
    font-size: .6rem;
    padding: .2rem;
  }
}
.HeaderLinks_list__v_9Qj {
  margin: auto 0 auto auto;
}
.HeaderLinks_list__v_9Qj li {
  display: inline;
}

.HeaderLinks_vl__Icvu- {
	border-left: 1px solid #786A5B;
}

.HeaderLinks_logo_button__3iSDo {
	margin-right: 15px;
  color: #786A5B;
  font-size: 1.8rem;
  opacity: .7;
  text-decoration: none;
}

.HeaderLinks_logo_button__3iSDo:focus,
.HeaderLinks_logo_button__3iSDo:hover {
  text-decoration: none;
  font-weight: 400;
  color: #6d5e4f;
  opacity: 1;
}

@media(min-width: 576px) and (max-width: 767px) {
  .HeaderLinks_vl__Icvu- {
    height: 10px;
    font-size: 1rem;
  }
  .HeaderLinks_logo_button__3iSDo {
    font-size: 1.5rem;
  }
}

@media(max-width: 575px) {
  .HeaderLinks_vl__Icvu- {
    height: 5px;
    font-size: .5rem;
  }

  .HeaderLinks_logo_button__3iSDo {
    font-size: 1rem;
  }
}
.Landing_landing__3Nv-E {
	background-color: #E6DCD0;
  height: 100vh;
	display: flex;
 	flex-direction: column;
 	align-items: center;
 	justify-content: center;
  margin-bottom: 30px;
}

.Landing_greeting__Wl74V {
	font-size: 4.5rem;
	font-weight: 700;
  font-family: 'Roboto';
	color: #786A5B;
}

.Landing_quote__2ImMb {
	font-size: 1.5rem;
  font-family: 'Roboto';
  font-weight: 500;
  text-align: center;
  width: 600px;
  color: #786A5B;
}

.Landing_down__12fLC {
  position: absolute;
  top:85vh;
  font-size: 2.5em;
  color: #707070;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  text-decoration: none;
}

.Landing_down__12fLC:hover,
.Landing_down__12fLC:focus {
  cursor: pointer;
  opacity: 0.8;
  color: #786A5B;
}

@media(min-width: 576px) and (max-width: 767px) {
  .Landing_greeting__Wl74V {
    font-size: 3.5rem;
    font-weight: bold;
    color: #786A5B;
  }
  
  .Landing_quote__2ImMb {
    font-size: 1.1rem;
    text-align: center;
    width: 450px;
    color: #786A5B;
  }
}

@media(max-width: 575px) {
  .Landing_greeting__Wl74V {
    font-size: 2.2rem;
  }
  
  .Landing_quote__2ImMb {
    font-size: .7rem;
    width: 300px;
  }
}
.SubHeader_title__2pMsa {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 3.5rem;
  color: #4F6C78;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 200px;
}

@media(max-width: 576px) {
  .SubHeader_title__2pMsa {
    font-size: 2.3rem;
    margin-top: 125px;
  }
} 
.Abilities_layer__2qdP5 {
  padding-bottom: 40px;
}
.Abilities_header__3PMyr {
  text-align: center;
  font-weight: 700;
  font-family: 'Roboto';
  color: #4F6C78;
}

#Abilities_accord__11DIo {
  padding-bottom: 2.5rem;
}

#Abilities_accord-header__1qLbI button {
  background-color: #A9BCC4;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 400;
}

#Abilities_accord-header__1qLbI div {
  border: none;
}

#Abilities_accord-header__1qLbI button[aria-expanded='true'] {
  background-color: #e7f1ff;
  color: #4F6C78;
}

#Abilities_accord-header__1qLbI button:focus {
  box-shadow: none;
}

#Abilities_accord-header__1qLbI button:hover {
  background-color: #e7f1ff;
  color: #4F6C78;
}

#Abilities_accord-header__1qLbI ul li {
  color: #4F6C78;
  font-family: 'Roboto';
  font-weight: 400;
}

@media (max-width: 767px) {
  #Abilities_col_margin__PVM4u {
    margin-top: 60px;
  }
}

.p {
  background-color: grey;
}
.Projects_layer__1okkx {
  margin-bottom: 70px;
}

.Projects_image__1sZmy {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.Projects_title__yBbeZ {
  font-size: 2.2rem;
  color: #4F6C78;
}

.Projects_description__2Tods {
  font-size: .8rem;
  color: #4F6C78;
}

.Projects_plus__3elv9 {
  font-size: 1.2rem;
  position: absolute;
  color: #E6DCD0;
  background-color: transparent;
  text-decoration: none;
  border: none;
  top: 5px;
  right: 20px;
}

.Projects_plus__3elv9:focus-visible {
  outline: none;
}

#Projects_content__3cajD div {
  border-radius: 15px;
  align-items: center;
  justify-content: center;
}

#Projects_modal__1ioB_ {
  padding: 0;
  border-radius: 10px;
}

#Projects_modal__1ioB_ img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #000;
}

#Projects_close_btn__31d08 {
  cursor: pointer;
  position: absolute;
  color: #E6DCD0;
  background-color: transparent;
  text-decoration: none;
  border: none;
  top: 5px;
  right: 10px;
  z-index: 15;
}

@media(min-width: 768px) and (max-width: 991px) {
  
  .Projects_title__yBbeZ {
    font-size: 1.4rem;
  }
  
  .Projects_description__2Tods {
    font-size: .8rem;
  }
}

@media(min-width: 576px) and (max-width: 767px) {
  .Projects_image__1sZmy {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .Projects_title__yBbeZ {
    font-size: 1.4rem;
  }
  
  .Projects_description__2Tods {
    font-size: .8rem;
  }
}

@media(min-width: 450px) and (max-width: 575px) {
  .Projects_layer__1okkx {
    margin-bottom: 30px;
  }

  .Projects_image__1sZmy {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .Projects_title__yBbeZ {
    font-size: 1rem;
    padding: 0 15px;
  }
  
  .Projects_description__2Tods {
    font-size: .7rem;
    padding: 0 15px;
  }
}

@media(max-width: 449px) {
  .Projects_layer__1okkx {
    margin-bottom: 30px;
  }

  .Projects_image__1sZmy {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .Projects_title__yBbeZ {
    font-size: 1rem;
    padding: 0 15px;
  }
  
  .Projects_description__2Tods {
    font-size: .7rem;
    padding: 0 15px;
  }
}
#About_about__6o7y3 {
  position: relative;
}

#About_about__6o7y3 img {
  border-radius: 200px;
  float: left;
  height: 240px;
  width: 240px;
}

#About_about__6o7y3 p {
  color: #4f6c78;
  font-size: 1rem;
}

#About_contact__1HBRr {
  position: relative;
  display: inline-flex;
  width: 300px;
  height: 55px;
  margin: 30px auto 250px auto;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

#About_contact__1HBRr a {
  font-size: 2rem;
  letter-spacing: 1px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(-25px);
          transform: translateZ(-25px);
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

#About_contact__1HBRr a:before,
#About_contact__1HBRr a:after {
  position: absolute;
  content: "CONTACT";
  height: 55px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #4f6c78;
  box-sizing: border-box;
  border-radius: 5px;
}

#About_contact__1HBRr a:before {
  color: #fff;
  background: #a9bcc4;
  -webkit-transform: rotateY(0deg) translateZ(25px);
          transform: rotateY(0deg) translateZ(25px);
}

#About_contact__1HBRr a:after {
  color: #4f6c78;
  -webkit-transform: rotateX(90deg) translateZ(25px);
          transform: rotateX(90deg) translateZ(25px);
}

#About_contact__1HBRr a:hover {
  -webkit-transform: translateZ(-25px) rotateX(-90deg);
          transform: translateZ(-25px) rotateX(-90deg);
}

@media (min-width: 1199) {
  #About_about__6o7y3 img {
    height: 330px;
    width: 330px;
  }

  #About_about__6o7y3 p {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) and (max-width: 1199) {
  #About_about__6o7y3 img {
    height: 290px;
    width: 290px;
  }

  #About_about__6o7y3 p {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #About_about__6o7y3 img {
    height: 210px;
    width: 210px;
  }

  #About_about__6o7y3 p {
    font-size: .8rem;
  }
}

@media (max-width: 575px) {
  #About_about__6o7y3 p {
    font-size: .7rem;
  }

  #About_contact__1HBRr {
    width: 200px;
    height: 55px;
    margin: 30px auto 150px auto;
  }

  #About_contact__1HBRr a {
    font-size: 1.4rem;
  }

  #About_contact__1HBRr a:before,
  #About_contact__1HBRr a:after {
    height: 55px;
    width: 200px;
  }
}

