@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.list {
  margin: auto 0 auto auto;
}
.list li {
  display: inline;
}

.vl {
	border-left: 1px solid #786A5B;
}

.logo_button {
	margin-right: 15px;
  color: #786A5B;
  font-size: 1.8rem;
  opacity: .7;
  text-decoration: none;
}

.logo_button:focus,
.logo_button:hover {
  text-decoration: none;
  font-weight: 400;
  color: #6d5e4f;
  opacity: 1;
}

@media(min-width: 576px) and (max-width: 767px) {
  .vl {
    height: 10px;
    font-size: 1rem;
  }
  .logo_button {
    font-size: 1.5rem;
  }
}

@media(max-width: 575px) {
  .vl {
    height: 5px;
    font-size: .5rem;
  }

  .logo_button {
    font-size: 1rem;
  }
}