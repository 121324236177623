@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

#about {
  position: relative;
}

#about img {
  border-radius: 200px;
  float: left;
  height: 240px;
  width: 240px;
}

#about p {
  color: #4f6c78;
  font-size: 1rem;
}

#contact {
  position: relative;
  display: inline-flex;
  width: 300px;
  height: 55px;
  margin: 30px auto 250px auto;
  perspective: 1000px;
}

#contact a {
  font-size: 2rem;
  letter-spacing: 1px;
  transform-style: preserve-3d;
  transform: translateZ(-25px);
  transition: transform .25s;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

#contact a:before,
#contact a:after {
  position: absolute;
  content: "CONTACT";
  height: 55px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #4f6c78;
  box-sizing: border-box;
  border-radius: 5px;
}

#contact a:before {
  color: #fff;
  background: #a9bcc4;
  transform: rotateY(0deg) translateZ(25px);
}

#contact a:after {
  color: #4f6c78;
  transform: rotateX(90deg) translateZ(25px);
}

#contact a:hover {
  transform: translateZ(-25px) rotateX(-90deg);
}

@media (min-width: 1199) {
  #about img {
    height: 330px;
    width: 330px;
  }

  #about p {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) and (max-width: 1199) {
  #about img {
    height: 290px;
    width: 290px;
  }

  #about p {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #about img {
    height: 210px;
    width: 210px;
  }

  #about p {
    font-size: .8rem;
  }
}

@media (max-width: 575px) {
  #about p {
    font-size: .7rem;
  }

  #contact {
    width: 200px;
    height: 55px;
    margin: 30px auto 150px auto;
  }

  #contact a {
    font-size: 1.4rem;
  }

  #contact a:before,
  #contact a:after {
    height: 55px;
    width: 200px;
  }
}
