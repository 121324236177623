.landing {
	background-color: #E6DCD0;
  height: 100vh;
	display: flex;
 	flex-direction: column;
 	align-items: center;
 	justify-content: center;
  margin-bottom: 30px;
}

.greeting {
	font-size: 4.5rem;
	font-weight: 700;
  font-family: 'Roboto';
	color: #786A5B;
}

.quote {
	font-size: 1.5rem;
  font-family: 'Roboto';
  font-weight: 500;
  text-align: center;
  width: 600px;
  color: #786A5B;
}

.down {
  position: absolute;
  top:85vh;
  font-size: 2.5em;
  color: #707070;
  transform: rotate(90deg);
  text-decoration: none;
}

.down:hover,
.down:focus {
  cursor: pointer;
  opacity: 0.8;
  color: #786A5B;
}

@media(min-width: 576px) and (max-width: 767px) {
  .greeting {
    font-size: 3.5rem;
    font-weight: bold;
    color: #786A5B;
  }
  
  .quote {
    font-size: 1.1rem;
    text-align: center;
    width: 450px;
    color: #786A5B;
  }
}

@media(max-width: 575px) {
  .greeting {
    font-size: 2.2rem;
  }
  
  .quote {
    font-size: .7rem;
    width: 300px;
  }
}