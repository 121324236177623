@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.layer {
  margin-bottom: 70px;
}

.image {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.title {
  font-size: 2.2rem;
  color: #4F6C78;
}

.description {
  font-size: .8rem;
  color: #4F6C78;
}

.plus {
  font-size: 1.2rem;
  position: absolute;
  color: #E6DCD0;
  background-color: transparent;
  text-decoration: none;
  border: none;
  top: 5px;
  right: 20px;
}

.plus:focus-visible {
  outline: none;
}

#content div {
  border-radius: 15px;
  align-items: center;
  justify-content: center;
}

#modal {
  padding: 0;
  border-radius: 10px;
}

#modal img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #000;
}

#close_btn {
  cursor: pointer;
  position: absolute;
  color: #E6DCD0;
  background-color: transparent;
  text-decoration: none;
  border: none;
  top: 5px;
  right: 10px;
  z-index: 15;
}

@media(min-width: 768px) and (max-width: 991px) {
  
  .title {
    font-size: 1.4rem;
  }
  
  .description {
    font-size: .8rem;
  }
}

@media(min-width: 576px) and (max-width: 767px) {
  .image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 1.4rem;
  }
  
  .description {
    font-size: .8rem;
  }
}

@media(min-width: 450px) and (max-width: 575px) {
  .layer {
    margin-bottom: 30px;
  }

  .image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 1rem;
    padding: 0 15px;
  }
  
  .description {
    font-size: .7rem;
    padding: 0 15px;
  }
}

@media(max-width: 449px) {
  .layer {
    margin-bottom: 30px;
  }

  .image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 1rem;
    padding: 0 15px;
  }
  
  .description {
    font-size: .7rem;
    padding: 0 15px;
  }
}