@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.title {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 3.5rem;
  color: #4F6C78;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 200px;
}

@media(max-width: 576px) {
  .title {
    font-size: 2.3rem;
    margin-top: 125px;
  }
} 