@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.header { 
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 4rem;
  display: flex;
  background-image: linear-gradient(to top right, #E6DCD0, #fff);
  z-index: 10;
}

.header.active {
  background: #fff;
}

.name {
	font-size: 2rem;
	font-weight: 700;
  font-family: 'Roboto';
  padding-left: 15px;
  margin: auto auto auto 0;
	color: #786A5B;
	opacity: .7;
  text-decoration: none;
}

.name:hover,
.name:focus {
  text-decoration: none;
  color: #786A5B;
}

@media(min-width: 576px) and (max-width: 767px) {
  .name {
    font-size: 1.8rem;
  }
}

@media(max-width: 575px) {
  .name {
    font-size: 1.2rem;
  }
}