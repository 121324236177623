@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.layer {
  padding-bottom: 40px;
}
.header {
  text-align: center;
  font-weight: 700;
  font-family: 'Roboto';
  color: #4F6C78;
}

#accord {
  padding-bottom: 2.5rem;
}

#accord-header button {
  background-color: #A9BCC4;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 400;
}

#accord-header div {
  border: none;
}

#accord-header button[aria-expanded='true'] {
  background-color: #e7f1ff;
  color: #4F6C78;
}

#accord-header button:focus {
  box-shadow: none;
}

#accord-header button:hover {
  background-color: #e7f1ff;
  color: #4F6C78;
}

#accord-header ul li {
  color: #4F6C78;
  font-family: 'Roboto';
  font-weight: 400;
}

@media (max-width: 767px) {
  #col_margin {
    margin-top: 60px;
  }
}
