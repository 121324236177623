@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.link-button {
  background-color: transparent;
  font-size: 1.2rem;
  font-family: 'Roboto';
  font-weight: 400;
	position: relative;
	padding: 1rem;
  border-bottom: .5rem;
  color: #786A5B;
  opacity: .7;
  text-decoration: none;
  border: 0px;
}

.link-button:focus,
.link-button:hover {
  text-decoration: none;
  font-weight: 400;
  color: #6d5e4f;
  opacity: 1;
}

@media(min-width: 576px) and (max-width: 767px) {
  .link-button {
    font-size: 1rem;
    padding: .8rem;
  }
}

@media(max-width: 575px) {
  .link-button {
    font-size: .6rem;
    padding: .2rem;
  }
}